import { ReactNode } from "react"
import { satisfies } from 'compare-versions';

export const VersionedContent = ({version_constraint, condition, version, children}: {
  version_constraint: string
  condition: 'match_any' | 'no_match' | 'semver'
  version?: string
  children: ReactNode
}) => {

  if (condition == 'no_match' || condition == 'match_any') {
    const matches = version_constraint.split(',').map(v => v.trim()).includes(version || '')
    if (condition === 'no_match' && !matches) return <>{children}</>
    if (condition === 'match_any' && matches) return <>{children}</>
  }
  if (condition == 'semver' && version !== undefined) {
    try {
      if (satisfies(version, version_constraint)) {
        return <>{children}</>
      }
    }
    catch (err) {
      console.error(err)
    }
  }
  return <></>
}