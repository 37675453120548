import classNames from "classnames"
import { ReactNode } from "react"
import { Dismissable, DismissButton } from "../widget/Dismiss.client"
import { Button } from "../base/Button"
import { XMarkIcon } from "@heroicons/react/24/outline"

export interface StatusMessageProps {
    heading?: string
    children?: ReactNode
    type: "note" | "important" | "caution" | "warning" | "advisory" | "alert"
    className?: string
    dismissId?: false | string
}

export type StatusMessageTagName = 'h2' | 'h3' | 'h4' | 'div'

export const StatusMessage = ({heading, children, type, className, dismissId = false}: StatusMessageProps) => {
    return (
        <Dismissable dismissId={dismissId}>
            <div className={classNames("widget-status-message", type, className)}>
                <div className="icon"></div>
                <div>
                    { heading && (
                        <div className="widget-status-message-title flex items-center">
                            <span className="flex-grow py-2">{heading}</span>
                            {dismissId && <DismissButton as={Button} primary={false} color="transparent">
                            <span className="sr-only">Dismiss</span>
                            <XMarkIcon className="w-4" role="none" aria-hidden="true" />
                            </DismissButton>}
                        </div>
                    )}
                    <div className="pr-9">
                        {children}
                    </div>
                </div>
            </div>
        </Dismissable>
    )
}