'use client'
import { fetchWebpageMetadata, translatePath } from "@/lib/actions.server"
import classNames from "classnames"
import Link from "next/link"
import { HTMLProps, useEffect, useState } from "react"

export const SmartLink = (props: HTMLProps<HTMLAnchorElement>) => {
  const [title, setTitle] = useState(props.children)

  useEffect(() => {
    if (!props.href) {
      return
    }

    if (props.href?.startsWith("/")) {
      translatePath(props.href)
        .then(path => setTitle(path.label))
        .catch(err => console.error(err))
    }
    else {
      fetchWebpageMetadata(props.href)
      .then(({title}) => {
        if (title) {
          setTitle(title)
        }
      })
      .catch(err => console.error(err))
    }
  }, [props.href])

  const className = classNames(...[
    'rounded', 'border', 'border-gray-600', 'hover:border-blue-600', 'bg-white', 'p-0.5', 'text-blue-600',
    'no-underline'
  ])

  const favicion = props.href && !props.href.startsWith('#') && <img className="mb-1 mr-2" src={getFaviconUrl(props.href)} alt="favicon" width={16} height={16} />

  if (props.href?.startsWith("/")) {
    return <Link {...props} href={props.href as string} className={className}>
      {favicion}
      {title}
    </Link>
  }

  return (
    <a {...props} className={className}>
      {favicion}
      {title}
    </a>
  )
}

function getFaviconUrl(href: string) {
  if (href?.startsWith("/")) {
    return "/favicon.ico"
  }
  const url = new URL(href)
  return url.protocol + "//" + url.host + "/favicon.ico"
}
