export function load<T = any>(namespace: string): T | undefined {
  try {
    const serializedState = localStorage.getItem(namespace);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    // Probably means not on client side.
    // /console.error("Could not load state from localStorage", err);
    return undefined;
  }
};

export function save<T = any>(namespace: string, state: T) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage?.setItem(namespace, serializedState);
  } catch (err) {
    console.error("Could not save state to localStorage", err);
  }
};