import { createAppSlice } from "../createAppSlice"; 
import { type PayloadAction } from "@reduxjs/toolkit";
import { load, save } from "../utils";

export interface DismissState {
  messageIds: string[];
}

const initialState: DismissState = {
  messageIds: []
};

export const dismiss = createAppSlice({
  name: 'dismiss',
  initialState,
  reducers: (create) => ({
    ack: create.reducer(
      (state, action: PayloadAction<string>) => {
        state.messageIds.push(action.payload)
        save<DismissState>('dismiss', state)
      }
    ),
    initialize: create.reducer(
      (state) => {
        state.messageIds = load<DismissState>('dismiss')?.messageIds ?? []
      }
    )
  }),
  selectors: {
    messageIds: (state) => state.messageIds
  }
})

export const {ack} = dismiss.actions
export const {messageIds} = dismiss.selectors