'use client'
import { ack, messageIds } from "@/redux/features/dismiss"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import { Transition } from "@headlessui/react"
import { createContext, ElementType, ReactNode, useContext } from "react"

export type DismissableContext = {
  messageId: false | string
}

const DismissableContext = createContext<DismissableContext>({
  messageId: false
})

export const useDismissable = () => useContext(DismissableContext);

export const Dismissable = ({children, dismissId}:{
  children: ReactNode
  dismissId: false | string
}) => {
  const dismissed = useAppSelector(messageIds)

  return <DismissableContext.Provider value={{messageId: dismissId}}>
    <Transition
        show={!(dismissId && dismissed.includes(dismissId))}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
    {children}  
    </Transition>
  </DismissableContext.Provider>
}

interface DismissButtonProps<T extends ElementType> {
  as?: T;
  children?: React.ReactNode;
  // Extending the passed props to allow native attributes for the chosen HTML or React element.
  props?: React.ComponentPropsWithoutRef<T>;
}

export const DismissButton = <T extends React.ElementType = 'button'>(
  { children, as, ...props }: DismissButtonProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof DismissButtonProps<T>>
) => {
  const Tag: React.ElementType = as ?? 'button';
  const { messageId } = useDismissable()
  const dismissed = useAppSelector(messageIds)
  const dispatch = useAppDispatch()

  function onClick() {
    if (messageId && !dismissed.includes(messageId)) {
      dispatch(ack(messageId))
    }
  }
  
  return <Tag {...props} onClick={onClick}>{children}</Tag>;
};
