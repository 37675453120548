'use client'
import { Menu } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import classNames from "classnames"
import { HTMLProps, ReactNode } from "react"

const primaryColors = {
  navy: "bg-navy-600 border-navy-600 text-white hover:bg-navy-800",
  blue: "bg-blue-600 border-blue-600 text-white hover:bg-blue-800",
  pink: "bg-pink-600 border-pink-600 text-white hover:bg-pink-800",
  teal: "bg-teal-600 border-teal-600 text-white hover:bg-teal-800",
  yellow: "bg-yellow-600 border-yellow-600 text-white hover:bg-yellow-800",
  orange: "bg-orange-600 border-orange-600 text-white hover:bg-orange-800",
  gray: "bg-gray-600 border-gray-800 text-white hover:bg-gray-800",
  transparent: "bg-transparent text-gray-800 hover:bg-gray-600 hover:text-white"
}

const secondaryColors = {
  navy: "bg-gray-300 border-navy-600 text-navy-800 hover:bg-navy-700 hover:text-white",
  blue: "bg-gray-300 border-blue-600 text-blue-800 hover:bg-blue-700 hover:text-white",
  pink: "bg-gray-300 border-pink-600 text-pink-800 hover:bg-pink-700 hover:text-white",
  teal: "bg-gray-300 border-teal-600 text-teal-800 hover:bg-teal-700 hover:text-white",
  yellow: "bg-gray-300 border-yellow-600 text-yellow-800 hover:bg-yellow-700 hover:text-white",
  orange: "bg-gray-300 border-orange-600 text-orange-800 hover:bg-orange-700 hover:text-white",
  gray: "bg-gray-300 border-gray-600 text-gray-800 hover:bg-gray-600 hover:text-white",
  transparent: "bg-transparent border-transparent text-gray-800 hover:bg-gray-400 hover:shadow"
}


export const Button = ({children, color = "navy", primary = true, onClick}: {
  children: ReactNode, 
  color?: keyof typeof primaryColors | keyof typeof secondaryColors
  primary?: boolean
  onClick?: HTMLProps<HTMLButtonElement>['onClick']
}) => {
  const className = primary ? primaryColors[color] : secondaryColors[color]
  return <button onClick={onClick} className={classNames(className, 'p-3 rounded border outline-offset-1 focus:outline-blue-800 focus:outline-2 hover:shadow-inner')}>{children}</button>
}

export const Link = ({children, color = "navy", primary = true, ...props}: HTMLProps<HTMLAnchorElement> & {
  color?: keyof typeof primaryColors | keyof typeof secondaryColors
  primary?: boolean
}) => {
  const className = primary ? primaryColors[color] : secondaryColors[color]
  return <a {...props} className={classNames(props.className, className, 'p-3 rounded border outline-offset-1 focus:outline-blue-800 focus:outline-2 hover:shadow-inner')}>{children}</a>
}

export const ButtonWithOptions = ({children, color = "navy", primary = true, options, primaryOption, subtitle}: {
  children: ReactNode, 
  color?: keyof typeof primaryColors | keyof typeof secondaryColors
  primary?: boolean
  options: ReactNode[]
  primaryOption?: ReactNode
  subtitle?: ReactNode
}) => {
  const className = primary ? primaryColors[color] : secondaryColors[color]
  return <Menu as={'div'} className={'flex relative text-white'}>
  <Menu.Button className={(open) => classNames(className, 'flex gap-2 items-center', 'border rounded p-3 transition-colors duration-300')}>
    {children}
    <ChevronDownIcon className="w-4" />
    </Menu.Button>
    <Menu.Items as="div" className={classNames("absolute top-14 w-full rounded overflow-hidden flex flex-col z-10 border", 
      className?.split(' ').filter(c => c.startsWith('bg-')).join(' '),
      className?.split(' ').filter(c => c.startsWith('text-')).join(' '),
      className?.split(' ').filter(c => c.startsWith('border')).join(' ')
    )}>
      {primaryOption !== undefined && (
        <>
          <Menu.Item as={'div'} className={classNames("text-left", 
            className?.split(' ').filter(c => c.startsWith('hover:text-')).join(' '),
            className?.split(' ').filter(c => c.startsWith('hover:bg-')).join(' '))}
            >
            {primaryOption}
          </Menu.Item>

          {subtitle !== undefined && (
            <>
              <hr className={classNames(primary ? "border-white/50" : "border-black/30", "mx-3")} />
              <h4 className={classNames(primary ? "text-white/50" : "text-black/50", "px-2 pt-2 text-sm")} >{subtitle}</h4>
            </>
          )}
        </>
      )}
      {options.map((option, i) => (
        <Menu.Item key={i} as={'div'} className={classNames("text-left", 
          className?.split(' ').filter(c => c.startsWith('hover:text-')).join(' '),
          className?.split(' ').filter(c => c.startsWith('hover:bg-')).join(' '))}
        >{option}</Menu.Item>
      ))}
    </Menu.Items>
</Menu>
}