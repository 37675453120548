'use client'

import React, { ElementType } from 'react'
import { Tab } from '@headlessui/react'
import { Body } from "./Body"
import classNames from "classnames";
import '@/styles/widget/tabs.css'


interface TabProp {
    label: React.ReactNode
    labelTagName?: TabTagName
    content?: {
        processed: string
    }
    node?: React.ReactNode
}

export type TabTagName = 'h2' | 'h3' | 'h4' | 'div'

export interface TabsProps {
    tabs: TabProp[]
}

export function Tabs({tabs}: TabsProps) {
  
  return (
    <div className="widget-tabs">
      <Tab.Group>
        <Tab.List className="flex gap-2">
          {tabs.map((t, i) => (
            <Tab
              key={i}
              as={t.labelTagName || 'h3'}
              className={({ selected }) =>
                classNames(
                  'widget-tabs-title',
                  selected
                    ? 'active text-navy-600 shadow'
                    : 'text-gray-800 hover:bg-white/60 hover:text-navy-600'
                )
              }
            >
              {t.label}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {tabs.map((t:TabProp, idx) => (
            <Tab.Panel
              key={idx}
              className='widget-tabs-container'
            >
             { t.content != null ? (
                <Body value={t.content.processed} />
              ) : t.node }
              
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
