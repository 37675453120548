'use client'
import { createContext, ReactNode, useContext } from "react";

export type UserContext = {
  isAcquian: boolean
  isLoggedIn: boolean
}

const UserContext = createContext<UserContext>({
  isAcquian: false,
  isLoggedIn: false
})

export const useUserInformation = () => useContext(UserContext);

export const UserInformationProvider = (props: UserContext & {
  children: ReactNode
}) => {
  return <UserContext.Provider value={props}>
    {props.children}
  </UserContext.Provider>
}